<template>
	<div v-if="!project.construction_sites.length">
		無工地資料，請新增工地
	</div>
	<div v-else>
		<loading mode="fixed" image="1" size="lg" v-if="loading" style="z-index:99999"/>
		<alert :errors="errors" v-if="errors.length"></alert>
		<button-group class="col-12" :buttons="buttons"></button-group>
		<project-result-gmap-viewer class="mt-3" :project="project" :position="position" :points="showPoints" v-model="showMap"></project-result-gmap-viewer>
		<!-- <input-checkbox :options="project.construction_sites" :keys="{value: 'id', text: 'name'}" v-model="showConstructionSiteIds"></input-checkbox>
		<input-checkbox options="包含其餘工地連接點" boolean v-model="acrossConstructionSites"></input-checkbox> -->
		<!-- <project-result-info class="mt-3" :project="project" :sites="sites"></project-result-info>
		<project-result-point-list class="mt-3" :project="project" :points="points"></project-result-point-list> -->
		<template v-if="editable">
			<div class="p-2 mt-3" v-for="(site, key) in project.construction_sites" :key="key">
				<hr />
				<b-row class="justify-content-between">
					<h5>工地{{site.id}}：{{site.name}}</h5>
					<router-link :to="{name: '編輯案件成果', params: {siteID: site.id}}" target="_blank">
						<i class="fa-fw fas fa-map-marked-alt"></i> 管線編輯工具
					</router-link>
				</b-row>
				<result-viewer :project="project" :site="site" :permission="permission" :project-data-type="projectDataType" v-if="measuredStatusIndexList.includes(site.status_index)" @reload="reload"></result-viewer>
				<div class="p-2" v-else-if="site.status_index === $store.getters.enum('construction_site.status.assigned').index">尚未上傳工地表單
					<b-button v-b-modal.construction-modal variant="outline-secondary">填寫工地表單</b-button>
						<b-modal id="construction-modal" title="工地表單填寫" hide-footer>
							<div class="container">
								<form ref="missionResultForm" @submit.prevent="submitForm" novalidate>
									<!-- 基本資訊 -->
									<b-card class="mb-3">
										<h5 class="font-weight-bold">基本資訊</h5>
										<b-form-group label="工地名稱:" label-class="font-weight-bold">
											<b-form-input v-model="formData.siteName" disabled></b-form-input>
										</b-form-group>
										<b-form-group label="地址:" label-class="font-weight-bold">
											<b-form-input v-model="formData.address" disabled></b-form-input>
										</b-form-group>
									</b-card>

									<!-- 必填欄位 -->
									<b-card class="mb-3">
										<h5 class="font-weight-bold">必填欄位</h5>
										<b-form-group label="已銑鋪:" label-class="font-weight-bold">
											<b-form-radio-group v-model="formData.paved" :options="yesNoOptions" name="paved-options" required></b-form-radio-group>
										</b-form-group>
										<b-form-group label="無法測量:" label-class="font-weight-bold" :disabled="!formData.paved">
											<b-form-radio-group v-model="formData.unmeasurable" :options="yesNoOptions" @change="handleUnmeasurableChange" name="unmeasurable-options"  required></b-form-radio-group>
										</b-form-group>
										<b-form-group label="無法測量原因:" label-class="font-weight-bold">
											<b-form-select v-model="formData.reason" :disabled="!(formData.unmeasurable == '1')"  required>
												<option :value="null">請選擇</option>
												<option value="現場仍在施工">現場仍在施工</option>
												<option value="私有地且無法聯絡地主">私有地且無法聯絡地主</option>
												<option value="私有地且拒絕測量人員進入">私有地且拒絕測量人員進入</option>
												<option value="GPS無法收斂">GPS無法收斂</option>
											</b-form-select>
										</b-form-group>
										<b-form-group label="已完成:" label-class="font-weight-bold">
											<b-form-radio-group v-model="formData.completed" :options="yesNoOptions" name="completed-options" required></b-form-radio-group>
										</b-form-group>
									</b-card>

									<!-- 資料錯誤區塊 -->
									<b-card class="mb-3">
										<h5 class="font-weight-bold">資料錯誤區塊</h5>
										<b-form-group label="施工照片:" label-class="font-weight-bold">
											<b-form-radio-group v-model="formData.photo" :options="photoOptions" name="photo-options"></b-form-radio-group>
										</b-form-group>								
										<b-form-group label="管溝修復/測點噴註照:" label-class="font-weight-bold">
											<b-form-radio-group v-model="formData.repairPhoto" :options="photoOptions" name="-repair-photo-options"></b-form-radio-group>
										</b-form-group>
										<b-form-group label="施工略圖:" label-class="font-weight-bold">
											<b-form-radio-group v-model="formData.sketchPhoto" :options="photoOptions" name="sketch-photo-options"></b-form-radio-group>
										</b-form-group>
										<b-form-group label="管線設計圖:" label-class="font-weight-bold">
											<b-form-radio-group v-model="formData.pipelinePhoto" :options="photoOptions" name="pipeline-photo-options"></b-form-radio-group>
										</b-form-group>
									</b-card>

									<!-- 設備編號區塊 -->
									<b-card class="mb-3">
										<h5 class="font-weight-bold">設備編號</h5>
										<b-form-group label="基站:" label-class="font-weight-bold">
											<b-form-select v-model="formData.baseStation" required>
												<option :value="null">請選擇</option>
												<option value="國土">國土</option>
												<option value="建國北路">建國北路</option>
												<option value="師大">師大</option>
												<option value="北投">北投</option>
												<option value="新店">新店</option>
												<option value="瑞芳">瑞芳</option>
												<option value="屏東">屏東</option>
											</b-form-select>
										</b-form-group>
										<b-form-group label="F90:" label-class="font-weight-bold">
											<b-form-select v-model="formData.f90" required>
												<option :value="null">請選擇</option>
												<option value="F90013811007">F90013811007</option>
												<option value="F90013804042">F90013804042</option>
												<option value="F90029908007">F90029908007</option>
												<option value="F90013804040">F90013804040</option>
												<option value="F90013804050">F90013804050</option>
												<option value="E600312120192G">E600312120192G</option>
												<option value="231116867014666">231116867014666</option>
												<option value="231116867015632">231116867015632</option>
												<option value="F90049006064">F90049006064</option>
												<option value="231296856000009">231296856000009</option>
												<option value="232086856001199">232086856001199</option>
												<option value="232086856000645">232086856000645</option>
												<option value="232086856000704">232086856000704</option>
												<option value="E600312220079G">E600312220079G</option>
												<option value="230386867007354">230386867007354</option>
												<option value="E600312220051G">E600312220051G</option>
											</b-form-select>
										</b-form-group>
										<b-form-group label="經緯儀:" label-class="font-weight-bold">
											<b-form-select v-model="formData.theodolite" required>
												<option :value="null">請選擇</option>
												<option value="LZ1149">LZ1149</option>
												<option value="LZ4549">LZ4549</option>
												<option value="T10106">T10106</option>
												<option value="M00774">M00774</option>
											</b-form-select>
										</b-form-group>
									</b-card>

									<!-- 備註 -->
									<b-card class="mb-3">
										<h5 class="font-weight-bold">備註</h5>									
										<b-form-group>
											<b-form-textarea v-model="formData.note" rows="5" max-rows="5"></b-form-textarea>
										</b-form-group>
									</b-card>

									<!-- 協同人員區塊 -->
									<b-card class="mb-3">
										<h5 class="font-weight-bold">設定協同人員</h5>
										<b-form-group label="是否有協同人員:" label-class="font-weight-bold">
											<b-form-radio-group v-model="formData.withPartner" :options="yesNoOptions" @change="handleWithPartnerChange" name="withPartner-options"></b-form-radio-group>
										</b-form-group>
										<b-form-group label="輸入姓名1:" label-class="font-weight-bold" :disabled="!(formData.withPartner == '1')">
											<b-form-select v-model="formData.partner1">
												<option :value="null">請選擇</option>
												<option v-for="user in partnerList" :key="user.id" :value="user.id">
													{{ user.name }}
												</option>									
											</b-form-select>
										</b-form-group>
										<b-form-group label="輸入姓名2:" label-class="font-weight-bold" :disabled="!(formData.withPartner == '1')">
											<b-form-select v-model="formData.partner2">
												<option :value="null">請選擇</option>
												<option v-for="user in partnerList" :key="user.id" :value="user.id">
													{{ user.name }}
												</option>										
											</b-form-select>
										</b-form-group>
										<b-form-group label="輸入姓名3:" label-class="font-weight-bold" :disabled="!(formData.withPartner == '1')">
											<b-form-select v-model="formData.partner3">
												<option :value="null">請選擇</option>
												<option v-for="user in partnerList" :key="user.id" :value="user.id">
													{{ user.name }}
												</option>
											</b-form-select>
										</b-form-group>
									</b-card>
								
									<!-- Submit Button -->
									<b-button variant="primary" class="w-100" @click="submitForm">上傳</b-button>
								</form>
							</div>
						</b-modal>
				</div>
				<div class="p-2" v-else-if="site.status_index === $store.getters.enum('construction_site.status.unassigned').index">尚未指派</div>
			</div>
		</template>
	</div>
</template>

<script>
import ResultViewer from '@/components/Project/Result/ResultViewer'
import ProjectResultGmapViewer from '@/components/Project/Result/ProjectResultGmapViewer.vue'
import ProjectResultInfo from '@/components/Project/Result/ProjectResultInfo.vue'
import ProjectResultPointList from '@/components/Project/Result/ProjectResultPointList.vue'
import ButtonGroup from '@/components/Button/ButtonGroup'
import fileManager from '@/utils/file';
import Loading from '@/components/Loading/Loading.vue'
import InputCheckbox from '@/components/Input/InputCheckbox';
import Alert from '@/components/Alert/Alert.vue';
import { deepCopy, checkAllTrue } from '@/utils/assist';
import { GetPointTargetMaxAmount, IsFacilityType } from '@/gml/utils'

export default {
	name: "ProjectResultViewer",
	components: {
		ResultViewer,
		ProjectResultGmapViewer,
		ProjectResultInfo,
		ProjectResultPointList,
		ButtonGroup,
		Loading,
		InputCheckbox,
		Alert,
	},
	props: {
		project: {
			type: Object
		},
		permission: {
			type: Object
		},
		projectDataType: {
			type: Array
		},
	},
	data() {
		return {
			groupID: Number.isInteger(parseInt(this.$route.params.groupID)) ? parseInt(this.$route.params.groupID) : -1,
			projectType: Number.isInteger(parseInt(this.$route.params.projectType)) ? parseInt(this.$route.params.projectType) : -1,
			projectID: Number.isInteger(parseInt(this.$route.params.projectID)) ? parseInt(this.$route.params.projectID) : -1,
			call: {},
			loading: true,
			showConstructionSiteIds: [],
			acrossConstructionSites: true,
			errors: [],
			showMap: false,
			buttons: [
				{
					//icon, label
					button: { icon: "fas fa-map", label: "顯示地圖" },
					//function, link, route
					action: { type: 'function', function: () => this.showMap = true },
					visible: () => !this.showMap,
				},
				{
					//icon, label
					button: { icon: "far fa-map", label: "隱藏地圖" },
					//function, link, route
					action: { type: 'function', function: () => this.showMap = false },
					visible: () => this.showMap,
				},
				{
					//icon, label
					button: { icon: "fas fa-download", label: "下載點位清冊" },
					//function, link, route
					action: { type: 'dropdown', dropdown: [
						{
							//icon, label
							button: { label: "大地起伏表(QGIS專用)" },
							//function, link, route
							action: { type: 'function', function: this.downloadAllConstructionSitesPointsPositionCsvForQgis },
						},
						{
							//icon, label
							button: { label: "大地起伏表" },
							//function, link, route
							action: { type: 'function', function: this.downloadAllConstructionSitesPointsPositionCsv },
						},
						{
							//icon, label
							button: { label: "點位csv" },
							//function, link, route
							action: { type: 'function', function: this.downloadAllConstructionSitesPointsToOrthometricHeight },
						},
					]},
				},
				{
					//icon, label
					button: { icon: "fas fa-retweet", label: "轉換點位正高" },
					//function, link, route
					action: { type: 'function', function: this.transformMultiConstructionSitesOrthometricHeight },
				},
			],
			formData: {
				siteName: '',
				address: '',
				paved: '',
				unmeasurable: '',
				reason: null,
				completed: '',
				photo: '0',
				repairPhoto: '0',
				sketchPhoto: '0',
				pipelinePhoto: '0',
				baseStation: null,
				f90: null,
				theodolite: null,
				note: '',
				withPartner: false,
				partner1: null,
				partner2: null,
				partner3: null,
				mission_id: null,
			},
			yesNoOptions: [
				{ text: "是", value: "1" },
				{ text: "否", value: "0" },
			],
			photoOptions: [
				{ text: "是", value: "1" },
				{ text: "否", value: "0" },
				{ text: "疑義", value: "2" },
			],
			partnerList: []
		}
	},
	created() {
		this.init()
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value, this.onLoadEnd)
			}
		},
	},
	mounted() {
		// 繞過app，在網頁上填寫工地表單
		try {
			this.formData.address = this.project.address
			this.formData.siteName = this.showConstructionSites[0].name
	
			// 取得使用者列表供設定協同人員
			this.$axios.getUserList(null, (response) => {			
				this.partnerList = response.data.map(ele => {
					return { id: ele.id, name: ele.name}
				})			
			}, (error) => {
				alert("無法取得協同人員列表，請稍後再試")
				console.error("取得協同人員列表失敗:" + error)
			})
	
			this.$axios.getMissionList({construction_sites_id: [this.showConstructionSites[0].id]}, (response) => {
				console.log('mission_id: ' + response.data[0].id)
				this.formData.mission_id = response.data[0].id
			}, (error) => {
				alert("無法取得指派任務資料，請稍後再試")
				console.error('取得指派任務資料錯誤: ' + error)
			})			
		} catch (error) {
			console.log("填寫工地表單功能錯誤，請稍後再試" + error)
			alert("填寫工地表單功能錯誤，請稍後再試")			
		}
	},
	computed: {
		position() {
			return this.project.construction_sites.reduce((obj, site, index, sites) => {
				return Object.keys(site.position).reduce((pos, key) => {
					if(pos[key] === undefined) {
						pos[key] = 0;
					}
					pos[key] += site.position[key] / sites.length;
					return pos
				}, obj)
			}, {})
		},
		editable() {
			return this.checkPermission('edit') || this.checkPermission('write') ? true : false
		},
		measuredStatusIndexList() {
			return [
				'measured',
				'measurement_finished',
				'remeasured',
			].map(status => this.$store.getters.enum(`construction_site.status.${status}`).index)
		},
		allPoints() {
			return this.project.construction_sites.map(site => site.points).flat()
		},
		showConstructionSites() {
			return this.project.construction_sites.filter(site => this.showConstructionSiteIds.includes(site.id))
		},
		showPoints() {
			let points = this.showConstructionSites.map(site => site.points).flat()
			if(!this.checkPointCoordinates(points)) {
				points = points.filter(p => p.coordinate)
			}
			if(!this.acrossConstructionSites)
				return points
			let pointNames = points.map(point => point.name)
			let targetNames = points.map(point => point.targets).flat().filter(name => !pointNames.includes(name))
			return [
				...points,
				...this.allPoints.filter(point => targetNames.includes(point.name)).map(point => {
					return {
						...point,
						form_data: Object.keys(point.form_data).reduce((obj, key) => {
							if(!key.startsWith('連接點'))
								obj[key] = deepCopy(point.form_data[key])
							return obj
						}, {})
					}
				})
			]
		},
	},
	methods:{
		reload() {
			// this.$emit("reload")
			location.reload()
		},
		init() {
			this.showConstructionSiteIds = this.project.construction_sites.map(site => site.id)
			this.acrossConstructionSites = true
		},
		checkPointCoordinates(points) {
			let errorPoints = points.filter(p => !p.coordinate)
			if(errorPoints.length) {
				alert(`部分點位未取得測量座標，請重新上傳測量座標csv`);
				this.errors = [{
					title: `部分點位未取得測量座標，請重新上傳測量座標csv`,
					messages: errorPoints.map(p => p.name).join(', '),
				}]
			}
		},
		checkPermission(action_key) {
			if(!this.groupID) return this.$store.getters.isDeveloper;
			if(!this.permission) return false;
			return this.permission.actions.find(action => action.key === action_key)
		},
		transformMultiConstructionSitesOrthometricHeight() {
			this.$set(this.call, "transformMultiConstructionSitesOrthometricHeight", false)
			this.$axios.transformMultiConstructionSitesOrthometricHeight({
				construction_sites_id: this.project.construction_sites.map(site =>site.id)
			}, (response) => {
				alert(`${response.fail_count}個點位轉換失敗，共轉換${response.all_count}個點位。`);
				if(response.fail_count < response.all_count) {
					this.$emit("reload");
				}
				this.call.transformMultiConstructionSitesOrthometricHeight = true
			}, (error) => {
				// let errors = Array.isArray(error.response.data.errors) ? error.response.data.errors : Object.values(error.response.data.errors);
				// switch(error.response.status) {
				// 	case 422:
				// 		alert(`轉換失敗！資料錯誤：\n${errors.flat().join("\n")}`);
				// 		break;
				// 	default:
				// 		alert(error);
				// 		break;
				// }
				this.call.transformMultiConstructionSitesOrthometricHeight = true
			})
		},
		downloadAllConstructionSitesPointsPositionCsv(){
			let sites = this.project.construction_sites.filter(site => site.status_index > this.$store.getters.enum('construction_site.status.assigned').index)
			let points = sites.map(site => site.points).flat()

			let content = ["序號,點號,N,E,橢球高,大地起伏,正高,埋深,種類,地表高度,目標,目標2,目標3"]
			points.forEach( (pt, index, arr) => {
				let coordinate = this.getPointCoordinate(pt.coordinate)
				content.push([
					index + 1,
					pt.name,
					coordinate.twd97_y,
					coordinate.twd97_x,
					coordinate.ellipsoidal_height,
					coordinate.orthometric_height !== null ? (coordinate.ellipsoidal_height - coordinate.orthometric_height).toFixed(3) : '',
					coordinate.orthometric_height !== null ? coordinate.orthometric_height : '',
					...pt.transformed_data ? [
						pt.transformed_data.depth,
						pt.transformed_type.type,
						0,
						!IsFacilityType(pt.transformed_type.type) ? pt.transformed_data.target1 : '',
						!IsFacilityType(pt.transformed_type.type) ? pt.transformed_data.target2 : '',
						!IsFacilityType(pt.transformed_type.type) ? pt.transformed_data.target3 : ''
					] : []
				].join(","))
			})
			fileManager.saveFile(`${this.project.excavation_permit}-${this.project.address}-大地起伏轉換`, "csv", content.join("\r\n"), {bom:true})
		},
		downloadAllConstructionSitesPointsPositionCsvForQgis() {
			let sites = this.project.construction_sites.filter(site => site.status_index > this.$store.getters.enum('construction_site.status.assigned').index)
			let allPoints = sites.map(site => site.points).flat()
			let points = allPoints.filter(point => point.point_name === point.name)

			let content = ["序號,點號,N,E,橢球高,大地起伏,正高,埋深,種類,地表高度,目標,目標2,目標3"]
			points.forEach( (pt, index, arr) => {
				let coordinate = this.getPointCoordinate(pt.coordinate)
				let targets = !IsFacilityType(pt.transformed_type.type) ? pt.targets.map(target => {
					let p = allPoints.find(p => p.name === target)
					return p ? p.point_name : ''
				}) : []
				content.push([
					index + 1,
					pt.name,
					coordinate.twd97_y,
					coordinate.twd97_x,
					coordinate.ellipsoidal_height,
					coordinate.orthometric_height !== null ? (coordinate.ellipsoidal_height - coordinate.orthometric_height).toFixed(3) : '',
					coordinate.orthometric_height !== null ? coordinate.orthometric_height : '',
					...pt.transformed_data ? [
						pt.transformed_data.depth,
						pt.transformed_type.type,
						0,
						...Object.values({
							...Object.mapKeys(Array(GetPointTargetMaxAmount()).fill(''), (v, i) => i),
							...Object.mapKeys(targets, (v, i) => i),
						})
					] : []
				].join(","))
			})
			fileManager.saveFile(`${this.project.excavation_permit}-${this.project.address}-大地起伏轉換(QGIS專用)`, "csv", content.join("\r\n"), {bom:true})
		},
		downloadAllConstructionSitesPointsToOrthometricHeight(){
			let sites = this.project.construction_sites.filter(site => site.status_index > this.$store.getters.enum('construction_site.status.assigned').index)	
			let content = []
			sites.map(site => { 
				site.points.forEach( (pt, index, arr) => {
					let coordinate = this.getPointCoordinate(pt.coordinate)
					content.push([
						pt.name,
						coordinate.twd97_y,
						coordinate.twd97_x,
						coordinate.ellipsoidal_height,
					].join(","))
				} )
			})
			return fileManager.saveFile(`${this.project.excavation_permit}-${this.project.address}-點位CSV`, "csv", content.join("\r\n"), {bom:true})
		},
		getPointCoordinate(coordinate) {
			return {
				twd97_y : coordinate ? coordinate.twd97_y : 0,
				twd97_x : coordinate ? coordinate.twd97_x : 0,
				ellipsoidal_height : coordinate ? coordinate.ellipsoidal_height : 0,
				orthometric_height : coordinate ? coordinate.orthometric_height : 0,
			}
		},
		handleWithPartnerChange(value) {
			if (!(value == '1')) {
				this.formData.partner1 = null
				this.formData.partner2 = null
				this.formData.partner3 = null
			}
		},
		handleUnmeasurableChange(value) {
			if (value == '0') {				
				this.formData.reason = null
			}			
		},
		submitForm() {			
			// 工地表單送出
			try {
				const form = this.$refs.missionResultForm[0]
				if (!form.checkValidity()) {
					alert('請確認必填欄位與設備編號選項')				
					return
				}			
									
				// 整理協同夥伴
				let partners = []
				if(this.formData.withPartner) {
					if(this.formData.partner1 != null) {
						partners.push(this.formData.partner1.toString())
					}
					if(this.formData.partner2 != null) {
						partners.push(this.formData.partner2.toString())
					}
					if(this.formData.partner3 != null) {
						partners.push(this.formData.partner3.toString())
					}
				}
				let requestBody = {
					creator_id: this.permission.user_id,
					mission_id: this.formData.mission_id,
					construction_site_id: this.showConstructionSites[0].id,
					data: {
						"必填欄位_已銑鋪": this.formData.paved,
						"必填欄位_無法測量": this.formData.unmeasurable,
						"必填欄位_已完成": this.formData.completed,
						"資料錯誤區塊_施工照片": this.formData.photo,
						"資料錯誤區塊_管溝修復/測點噴註照": this.formData.repairPhoto,
						"資料錯誤區塊_施工略圖": this.formData.sketchPhoto,
						"資料錯誤區塊_管線設計圖": this.formData.pipelinePhoto,
						"基本資訊_工地名稱": this.formData.siteName,
						"基本資訊_地址": this.formData.address,
						"必填欄位_無法測量原因": this.formData.reason,
						"資料錯誤區塊_施工照片說明": "",
						"資料錯誤區塊_管溝修復/測點噴註照說明": "",
						"資料錯誤區塊_施工略圖說明": "",
						"資料錯誤區塊_管線設計圖說明": "",
						"設備編號_基站": this.formData.baseStation,
						"設備編號_F90": this.formData.f90,
						"設備編號_經緯儀": this.formData.theodolite,
						"備註": this.formData.note,
						selectItems: {
							items: [
								{
									key: "必填欄位_已銑鋪",
									index: 0
								},
								{
									key: "必填欄位_已完成",
									index: 0
								},
								{
									key: "必填欄位_已完成",
									index: 1
								}
							]
						}
					},
					lat: this.showConstructionSites[0].lat,
					lng: this.showConstructionSites[0].lng,
					dynamic_form_id: "11",
					partners: partners
				}
				requestBody.data = JSON.stringify(requestBody.data)				
				this.$axios.uploadMissionResult(requestBody, (response) => {					
					alert("上傳工地任務基本資料成功!")
					window.location.reload()
				}, (error) => {
					alert("上傳工地任務基本資料失敗，請稍後再試")
					console.error("上傳工地任務基本資料失敗:" + error)
				})
			} catch (error) {
				console.log("上傳工地表單失敗，請稍後再試" + error)
				alert("上傳工地表單失敗，請稍後再試")
			}			
		},
	}
}
</script>

<style scoped>
.google-map {
	min-height: 500px;
	height: 500px;
}
</style>
