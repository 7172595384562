<template>
	<loading mode="fixed" image="1" size="lg" v-if="loading"/>
	<div class="p-2" v-else>
		<b-list-group-item class="p-0">
			<div class="px-md-4 px-2 py-3 d-flex justify-content-between align-items-center collapsed-icon" v-b-toggle="`${site.id}-result-data`">
				<b>工地成果</b>
			</div>
			<b-collapse :id="`${site.id}-result-data`" class="px-md-4 p-2">
				<template v-for="(data, key) in site.data">
					<b-row class="list-unstyled" v-if="data" :key="key">
						<font class="pl-3" style="flex: 0 1 7rem;">{{data.name}}：</font>{{data.value}}
					</b-row>
				</template>
				<hr />
				<template v-for="(data, key) in site.result.form_data">
					<b-row class="list-unstyled" v-if="data" :key="key">
						<font class="pl-3" style="flex: 0 1 7rem;">{{data.name}}：</font>{{data.value}}
					</b-row>
				</template>
			</b-collapse>
		</b-list-group-item>
		<b-list-group-item class="p-0">
			<div class="px-md-4 px-2 py-3 d-flex justify-content-between align-items-center collapsed-icon" v-b-toggle="`${site.id}-point-list`">
				<b>點位列表</b>
			</div>
			<b-collapse :id="`${site.id}-point-list`" class="px-md-4 p-2">
				<result-point-list :site="site" @reload="reload"></result-point-list>
			</b-collapse>
		</b-list-group-item>
		<b-list-group-item class="p-0">
			<div class="px-md-4 px-2 py-3 d-flex justify-content-between align-items-center collapsed-icon" v-b-toggle="`${site.id}-point-photo`">
				<b>測量資料</b>
			</div>
			<b-collapse :id="`${site.id}-point-photo`" class="px-md-4 p-2">
				<result-data-viewer class="col-12 mb-3" :site="site" :permission="permission" :project-data-type="projectDataType" @reload="reload"></result-data-viewer>
			</b-collapse>
		</b-list-group-item>
		<b-list-group-item class="p-0">
			<div class="px-md-4 px-2 py-3 d-flex justify-content-between align-items-center collapsed-icon" v-b-toggle="`${site.id}-four-pitch-data`">
				<b>四支距資料（北水巡查系統）</b>
			</div>
			<b-collapse :id="`${site.id}-four-pitch-data`" class="px-md-4 p-2">
				<result-four-pitch-point-list :site="site"></result-four-pitch-point-list>
			</b-collapse>
		</b-list-group-item>
		<div class="row col-12 justify-content-end">
			<b-button class="form-btn submit-btn" @click="measuredConstructionSite" v-if="editable && (site.status_index === $store.getters.enum('construction_site.status.remeasured').index)">確認工地已測量</b-button>
			<b-button class="form-btn clear-btn" @click="remeasureConstructionSite" v-if="editable && (site.status_index === $store.getters.enum('construction_site.status.measured').index || site.status_index === $store.getters.enum('construction_site.status.measurement_finished').index)">工地待補測</b-button>
			<b-button class="form-btn submit-btn" @click="finishConstructionSite" v-if="editable && (site.status_index === $store.getters.enum('construction_site.status.measured').index)">確認工地已完成</b-button>
			<!-- <b-button class="form-btn submit-btn" @click="updateConstructionSiteData">回復</b-button> -->
			<b-button class="form-btn submit-btn" v-b-modal.construction-modal>編輯工地成果</b-button>
		</div>
		<hr />
		<div class="progress-wrapper" v-if="downloading">
			<b-progress class="progress-bar-wrapper" :max="1">
				<b-progress-bar :value="1" striped animated></b-progress-bar>
			</b-progress>
		</div>
		<b-modal id="construction-modal" title="工地表單編輯" @show="loadModalForm" hide-footer>
			<div class="container">
				<form ref="missionResultForm" @submit.prevent="submitModalForm" novalidate>
					<!-- 基本資訊 -->
					<b-card class="mb-3">
						<h5 class="font-weight-bold">基本資訊</h5>
						<b-form-group label="工地名稱:" label-class="font-weight-bold">
							<b-form-input v-model="formData.siteName" disabled></b-form-input>
						</b-form-group>
						<b-form-group label="地址:" label-class="font-weight-bold">
							<b-form-input v-model="formData.address" disabled></b-form-input>
						</b-form-group>
						<b-form-group label="上傳時間:" label-class="font-weight-bold">
							<b-form-input type="datetime-local" v-model="formData.uploadTime"></b-form-input>							
						</b-form-group>						
					</b-card>

					<!-- 必填欄位 -->
					<b-card class="mb-3">
						<h5 class="font-weight-bold">必填欄位</h5>
						<b-form-group label="已銑鋪:" label-class="font-weight-bold">
							<b-form-radio-group v-model="formData.paved" :options="yesNoOptions" name="paved-options" required></b-form-radio-group>
						</b-form-group>
						<b-form-group label="無法測量:" label-class="font-weight-bold" :disabled="!formData.paved">
							<b-form-radio-group v-model="formData.unmeasurable" :options="yesNoOptions" @change="handleUnmeasurableChange" name="unmeasurable-options"  required></b-form-radio-group>
						</b-form-group>
						<b-form-group label="無法測量原因:" label-class="font-weight-bold">
							<b-form-select v-model="formData.reason" :disabled="!(formData.unmeasurable == '1')"  required>
								<option :value="null">請選擇</option>
								<option value="現場仍在施工">現場仍在施工</option>
								<option value="私有地且無法聯絡地主">私有地且無法聯絡地主</option>
								<option value="私有地且拒絕測量人員進入">私有地且拒絕測量人員進入</option>
								<option value="GPS無法收斂">GPS無法收斂</option>
							</b-form-select>
						</b-form-group>
						<b-form-group label="已完成:" label-class="font-weight-bold">
							<b-form-radio-group v-model="formData.completed" :options="yesNoOptions" name="completed-options" required></b-form-radio-group>
						</b-form-group>
					</b-card>

					<!-- 資料錯誤區塊 -->
					<b-card class="mb-3">
						<h5 class="font-weight-bold">資料錯誤區塊</h5>
						<b-form-group label="施工照片:" label-class="font-weight-bold">
							<b-form-radio-group v-model="formData.photo" :options="photoOptions" name="photo-options"></b-form-radio-group>
						</b-form-group>								
						<b-form-group label="管溝修復/測點噴註照:" label-class="font-weight-bold">
							<b-form-radio-group v-model="formData.repairPhoto" :options="photoOptions" name="-repair-photo-options"></b-form-radio-group>
						</b-form-group>
						<b-form-group label="施工略圖:" label-class="font-weight-bold">
							<b-form-radio-group v-model="formData.sketchPhoto" :options="photoOptions" name="sketch-photo-options"></b-form-radio-group>
						</b-form-group>
						<b-form-group label="管線設計圖:" label-class="font-weight-bold">
							<b-form-radio-group v-model="formData.pipelinePhoto" :options="photoOptions" name="pipeline-photo-options"></b-form-radio-group>
						</b-form-group>
					</b-card>

					<!-- 設備編號區塊 -->
					<b-card class="mb-3">
						<h5 class="font-weight-bold">設備編號</h5>
						<b-form-group label="基站:" label-class="font-weight-bold">
							<b-form-select v-model="formData.baseStation" required>
								<option :value="null">請選擇</option>
								<option value="國土">國土</option>
								<option value="建國北路">建國北路</option>
								<option value="師大">師大</option>
								<option value="北投">北投</option>
								<option value="新店">新店</option>
								<option value="瑞芳">瑞芳</option>
								<option value="屏東">屏東</option>
							</b-form-select>
						</b-form-group>
						<b-form-group label="F90:" label-class="font-weight-bold">
							<b-form-select v-model="formData.f90" required>
								<option :value="null">請選擇</option>
								<option value="F90013811007">F90013811007</option>
								<option value="F90013804042">F90013804042</option>
								<option value="F90029908007">F90029908007</option>
								<option value="F90013804040">F90013804040</option>
								<option value="F90013804050">F90013804050</option>
								<option value="E600312120192G">E600312120192G</option>
								<option value="231116867014666">231116867014666</option>
								<option value="231116867015632">231116867015632</option>
								<option value="F90049006064">F90049006064</option>
								<option value="231296856000009">231296856000009</option>
								<option value="232086856001199">232086856001199</option>
								<option value="232086856000645">232086856000645</option>
								<option value="232086856000704">232086856000704</option>
								<option value="E600312220079G">E600312220079G</option>
								<option value="230386867007354">230386867007354</option>
								<option value="E600312220051G">E600312220051G</option>
							</b-form-select>
						</b-form-group>
						<b-form-group label="經緯儀:" label-class="font-weight-bold">
							<b-form-select v-model="formData.theodolite" required>
								<option :value="null">請選擇</option>
								<option value="LZ1149">LZ1149</option>
								<option value="LZ4549">LZ4549</option>
								<option value="T10106">T10106</option>
								<option value="M00774">M00774</option>
							</b-form-select>
						</b-form-group>
					</b-card>

					<!-- 備註 -->
					<b-card class="mb-3">
						<h5 class="font-weight-bold">備註</h5>									
						<b-form-group>
							<b-form-textarea v-model="formData.note" rows="5" max-rows="5"></b-form-textarea>
						</b-form-group>
					</b-card>

					<!-- 協同人員區塊 -->
					<b-card class="mb-3">
						<h5 class="font-weight-bold">設定協同人員</h5>
						<b-form-group label="是否有協同人員:" label-class="font-weight-bold">
							<b-form-radio-group v-model="formData.withPartner" :options="yesNoOptions" @change="handleWithPartnerChange" name="withPartner-options"></b-form-radio-group>
						</b-form-group>
						<b-form-group label="輸入姓名1:" label-class="font-weight-bold" :disabled="!(formData.withPartner == '1')">
							<b-form-select v-model="formData.partner1">
								<option :value="null">請選擇</option>
								<option v-for="user in partnerList" :key="user.id" :value="user.id">
									{{ user.name }}
								</option>									
							</b-form-select>
						</b-form-group>
						<b-form-group label="輸入姓名2:" label-class="font-weight-bold" :disabled="!(formData.withPartner == '1')">
							<b-form-select v-model="formData.partner2">
								<option :value="null">請選擇</option>
								<option v-for="user in partnerList" :key="user.id" :value="user.id">
									{{ user.name }}
								</option>										
							</b-form-select>
						</b-form-group>
						<b-form-group label="輸入姓名3:" label-class="font-weight-bold" :disabled="!(formData.withPartner == '1')">
							<b-form-select v-model="formData.partner3">
								<option :value="null">請選擇</option>
								<option v-for="user in partnerList" :key="user.id" :value="user.id">
									{{ user.name }}
								</option>
							</b-form-select>
						</b-form-group>
					</b-card>
				
					<!-- Submit Button -->
					<b-button variant="primary" class="w-100" @click="submitModalForm">儲存</b-button>
				</form>
			</div>
		</b-modal>
	</div>
</template>

<script>
import ResultPointList from '@/components/Project/Result/ResultPointList.vue'
import ResultDataViewer from '@/components/Project/Result/ResultDataViewer.vue'
import ResultFourPitchPointList from '@/components/Project/Result/ResultFourPitchPointList.vue'
import BvTable from '@/components/Table/BvTable.vue'
import Loading from '@/components/Loading/Loading.vue'
import { checkAllTrue } from '@/utils/assist';

export default {
	name: 'ResultViewer',
	components: {
		ResultPointList,
		ResultDataViewer,
		ResultFourPitchPointList,
		BvTable,
		Loading,
	},
	props: {
		project: {
			type: Object,
		},
		site: {
			type: Object,
			default: () => { return {} }
		},
		permission: {
			type: Object
		},
		projectDataType: {
			type: Array
		},
	},
	data() {
		return {
			groupID: Number.isInteger(parseInt(this.$route.params.groupID)) ? parseInt(this.$route.params.groupID) : -1,
			projectType: Number.isInteger(parseInt(this.$route.params.projectType)) ? parseInt(this.$route.params.projectType) : -1,
			projectID: Number.isInteger(parseInt(this.$route.params.projectID)) ? parseInt(this.$route.params.projectID) : -1,
			call: {},
			loading: true,
			result: {},
			points: [],
			downloading: false,
			formData: {
				siteName: '',
				address: '',
				uploadTime: '',				
				paved: '',
				unmeasurable: '',
				reason: null,
				completed: '',
				photo: '0',
				repairPhoto: '0',
				sketchPhoto: '0',
				pipelinePhoto: '0',
				baseStation: null,
				f90: null,
				theodolite: null,
				note: '',
				withPartner: false,
				partner1: null,
				partner2: null,
				partner3: null,
				mission_id: null,
			},
			yesNoOptions: [
				{ text: "是", value: "1" },
				{ text: "否", value: "0" },
			],
			photoOptions: [
				{ text: "是", value: "1" },
				{ text: "否", value: "0" },
				{ text: "疑義", value: "2" },
			],
			partnerList: []
		}
	},
	created() {
	},
	watch: {
		call: {
			deep: true,
			immediate: true,
			handler(value) {
				this.loading = checkAllTrue(value, this.onLoadEnd)
			}
		},
	},
	mounted() {
		// 繞過app，在網頁上填寫工地表單
		try {
			// this.formData.address = this.project.address
			// this.formData.siteName = this.showConstructionSites[0].name
	
			// 取得使用者列表供設定協同人員
			this.$axios.getUserList(null, (response) => {			
				this.partnerList = response.data.map(ele => {
					return { id: ele.id, name: ele.name}
				})
				for (let i = 1; i < this.site.result.users.length; i++) {
					const user = this.site.result.users[i];					
					this.formData[`partner${i}`] = user.id
				}
			}, (error) => {
				alert("無法取得協同人員列表，請稍後再試")
				console.error("取得協同人員列表失敗:" + error)
			})		
		} catch (error) {
			console.log("編輯工地表單功能錯誤，請稍後再試" + error)
			alert("編輯工地表單功能錯誤，請稍後再試")			
		}
		//console.log(this.site.result)
	},
	computed: {
		editable() {
			return this.checkPermission('edit') ? true : false
		},
	},
	methods:{
		reload() { this.$emit('reload') },
		remeasureConstructionSite() {
			this.$axios.setConstructionSiteRemeasure(this.site.id, {}, (response) => {
				alert("更新成功");
				this.call.finishConstructionSite = true
				this.reload();
			}, (error) => {
				this.call.finishConstructionSite = true
			})
		},
		measuredConstructionSite() {
			this.$axios.setConstructionSiteMeasured(this.site.id, {}, (response) => {
				alert("更新成功");
				this.call.finishConstructionSite = true
				this.reload();
			}, (error) => {
				this.call.finishConstructionSite = true
			})
		},
		finishConstructionSite() {
			this.$set(this.call, "finishConstructionSite", false);
			this.$axios.setConstructionSiteComplete(this.site.id, {}, (response) => {
				alert("更新成功");
				this.call.finishConstructionSite = true
				this.reload();
			}, (error) => {
				this.call.finishConstructionSite = true
			})
		},
		updateConstructionSiteData() {
			this.$set(this.call, "updateConstructionSiteData", false);
			this.$axios.updateConstructionSiteData(this.site.id, {status: this.$store.getters.enum("construction_site.status.measured").index}, (response) => {
				alert("更新成功");
				this.call.updateConstructionSiteData = true
				this.reload();
			}, (error) => {
				this.call.updateConstructionSiteData = true
			})
		},
		checkPermission(action_key) {
			if(!this.groupID) return this.$store.getters.isDeveloper;
			if(!this.permission) return false;
			return this.permission.actions.find(action => action.key === action_key)
		},
		handleWithPartnerChange(value) {
			if (!(value == '1')) {
				this.formData.partner1 = null
				this.formData.partner2 = null
				this.formData.partner3 = null
			}
		},
		handleUnmeasurableChange(value) {
			if (value == '0') {				
				this.formData.reason = null
			}			
		},
		loadModalForm() {
			this.formData.siteName = this.site.result.construction_site_name
			this.formData.address = this.project.address
			this.formData.uploadTime = this.site.result.form_data["created_at"].value
			this.formData.unmeasurable = (this.site.result.form_data["必填欄位_無法測量"].value == "是")? "1" : "0"
			this.formData.paved = (this.site.result.form_data["必填欄位_已銑鋪"].value == "是")? "1" : "0"			
			if (this.site.result.form_data.hasOwnProperty('必填欄位_無法測量原因')) {
				this.formData.reason = this.site.result.form_data["必填欄位_無法測量原因"].value ?? null
			}
			this.formData.completed = (this.site.result.form_data["必填欄位_已完成"].value == "是")? "1" : "0"
			switch (this.site.result.form_data["資料錯誤區塊_施工照片"].value) {
				case "否":
					this.formData.photo =  "0"
					break
				case "是":
					this.formData.photo =  "1"
					break
				case "疑義":
					this.formData.photo =  "2"
					break
			}
			
			switch (this.site.result.form_data["資料錯誤區塊_管溝修復/測點噴註照"].value) {
				case "否":
					this.formData.repairPhoto = "0"
					break
				case "是":
					this.formData.repairPhoto = "1"
					break
				case "疑義":
					this.formData.repairPhoto = "2"
					break
			}
			switch (this.site.result.form_data["資料錯誤區塊_施工略圖"].value) {
				case "否":
					this.formData.sketchPhoto = "0"
					break
				case "是":
					this.formData.sketchPhoto = "1"
					break
				case "疑義":
					this.formData.sketchPhoto = "2"
					break	
			}
			switch (this.site.result.form_data["資料錯誤區塊_管線設計圖"].value) {
				case "否":
					this.formData.pipelinePhoto = "0"				
					break
				case "是":
					this.formData.pipelinePhoto = "1"
					break
				case "疑義":
					this.formData.pipelinePhoto =  "2"
					break
			}
			this.formData.baseStation = this.site.result.form_data["設備編號_基站"].value
			this.formData.f90 = this.site.result.form_data["設備編號_F90"].value
			this.formData.theodolite = this.site.result.form_data["設備編號_經緯儀"].value
			this.formData.note = this.site.result.form_data["備註"].value

			if(this.site.result.users && this.site.result.users.length > 1) {
				this.formData.withPartner = "1"
			} else {
				this.formData.withPartner = "0"
			}

			if (this.partnerList.length > 0 ){
				for (let i = 1; i < this.site.result.users.length; i++) {
					const user = this.site.result.users[i];					
					this.formData[`partner${i}`] = user.id
				}
			}
			// this.$nextTick()
		},
		submitModalForm() {			
			// 工地表單送出
			try {
				this.$nextTick(() => {

					// const form = this.$refs.missionResultForm
					// 正式機可能狀況
					// const form = this.$refs.missionResultForm[0]					
					// if (!form.checkValidity()) {
					// 	alert('請確認必填欄位與設備編號選項')				
					// 	return
					// }			

					if (!this.formData.paved || this.formData.paved == ""){
						alert('請確認必填欄位')
						return
					}
					if (!this.formData.unmeasurable || this.formData.unmeasurable == ""){
						alert('請確認必填欄位')
						return
					}
					if (!this.formData.completed || this.formData.completed == ""){
						alert('請確認必填欄位')
						return
					}
					if (!this.formData.baseStation || this.formData.baseStation == ""){
						alert('設備編號選項')
						return
					}
					if (!this.formData.f90 || this.formData.f90 == ""){
						alert('設備編號選項')
						return
					}
					if (!this.formData.theodolite || this.formData.theodolite == ""){
						alert('設備編號選項')
						return
					}
										
					// 整理協同夥伴
					let partners = []
					if(this.formData.withPartner) {
						if(this.formData.partner1 != null) {
							partners.push(this.formData.partner1.toString())
						}
						if(this.formData.partner2 != null) {
							partners.push(this.formData.partner2.toString())
						}
						if(this.formData.partner3 != null) {
							partners.push(this.formData.partner3.toString())
						}
					}
					let requestBody = {
						creator_id: this.permission.user_id,
						mission_id: this.site.result.mission_id,
						construction_site_id: this.site.result.construction_site_id,
						uploadTime: this.formData.uploadTime,
						data: {
							"必填欄位_已銑鋪": this.formData.paved,
							"必填欄位_無法測量": this.formData.unmeasurable,
							"必填欄位_已完成": this.formData.completed,
							"資料錯誤區塊_施工照片": this.formData.photo,
							"資料錯誤區塊_管溝修復/測點噴註照": this.formData.repairPhoto,
							"資料錯誤區塊_施工略圖": this.formData.sketchPhoto,
							"資料錯誤區塊_管線設計圖": this.formData.pipelinePhoto,
							"基本資訊_工地名稱": this.formData.siteName,
							"基本資訊_地址": this.formData.address,
							"必填欄位_無法測量原因": this.formData.reason,
							"資料錯誤區塊_施工照片說明": "",
							"資料錯誤區塊_管溝修復/測點噴註照說明": "",
							"資料錯誤區塊_施工略圖說明": "",
							"資料錯誤區塊_管線設計圖說明": "",
							"設備編號_基站": this.formData.baseStation,
							"設備編號_F90": this.formData.f90,
							"設備編號_經緯儀": this.formData.theodolite,
							"備註": this.formData.note,
							selectItems: {
								items: [
									{
										key: "必填欄位_已銑鋪",
										index: 0
									},
									{
										key: "必填欄位_已完成",
										index: 0
									},
									{
										key: "必填欄位_已完成",
										index: 1
									}
								]
							}
						},
						lat: this.site.result.lat,
						lng: this.site.result.lng,
						dynamic_form_id: "11",
						partners: partners
					}
					requestBody.data = JSON.stringify(requestBody.data)				
					this.$axios.uploadMissionResult(requestBody, (response) => {					
						alert("編輯工地任務基本資料成功!")
						window.location.reload()
					}, (error) => {
						alert("編輯工地任務基本資料失敗，請稍後再試")
						console.error("編輯工地任務基本資料失敗:" + error)
					})
				})
			} catch (error) {
				console.log("編輯工地表單失敗，請稍後再試" + error)
				alert("編輯工地表單失敗，請稍後再試")
			}			
		},
	}
}
</script>

<style scoped>
.google-map {
	min-height: 500px;
	height: 500px;
}

.progress-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #0005;
	z-index: 10;
}
.progress-bar-wrapper {
	width: 50%;
	margin: auto;
	top: 50%;
	background: #e1e6e690;
	box-shadow: 0 0 5px .5px #284a7090;
}
.progress-bar {
	background: #017ca7;
}
.progress-bar-striped {
	background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
	background-size: 1rem 1rem;
}
.progress-bar-animated {
	-webkit-animation: progress-bar-stripes 1s linear infinite;
	animation: progress-bar-stripes 1s linear infinite;
}
</style>
